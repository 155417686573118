const baseURL =
  process.env.REACT_APP_URL_ENV === "DEV" ||
  process.env.NODE_ENV === "development"
    ? "https://gahhalk85f.execute-api.ap-southeast-1.amazonaws.com/dev/"
    : "https://9drn6c5rri.execute-api.ap-south-1.amazonaws.com/dev/";

const defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json"
};

const invoicesURL = `${baseURL}invoices`;
const invoiceURL = `${baseURL}invoice`;
const invoicesBulkURL = `${baseURL}invoices-bulk`;
const ordersURL = `${baseURL}orders`;
const ordersBulkURL = `${baseURL}orders/bulk`;
const bulkOrdersPrefillURL = `${baseURL}orders/bulk/prefill`;
const productMappingURL = `${baseURL}orders/bulk/details`;
const companiesURL = `${baseURL}companies`;
const transporterDetailsURL = `${baseURL}transporter`;
const login = `${baseURL}login`;
const loginToken = `${baseURL}login-key`;
const invoicesMerge = `${baseURL}invoice-merge`;
const gatePassURL = `${baseURL}gatepass`;
const clean = `${baseURL}clean`;
const pestControl = `${baseURL}pest-control`;
const temperature = `${baseURL}temperature`;
const productsURL = `${baseURL}products`;
const inventoryURL = `${baseURL}inventory`;
const returnsURL = `${baseURL}returns`;
const returnsBulkURL = `${baseURL}returns/bulk`;
const returnsBulkPrefillURL = `${baseURL}returns/bulk/prefill`;
const returnDetailsURL = `${baseURL}return-details`;
const warehouseTimingsURL = `${baseURL}warehouse-timing`;
const vehiclesTimingsURL = `${baseURL}warehouse-vehicle`;
const partiesURL = `${baseURL}parties`;
const notificationsURL = `${baseURL}notifications`;
const alertsURL = `${baseURL}alerts`;
const templateURL = `${baseURL}template`;
const templateFailURL = `${baseURL}template/fail`;
const headers = `https://haj2oi1vwa.execute-api.ap-southeast-1.amazonaws.com/test/api/v1/parser/headers`;
const mapping = `https://haj2oi1vwa.execute-api.ap-southeast-1.amazonaws.com/test/api/v1/parser/file-data`;

const APIConstants = {
  invoiceURL,
  notificationsURL,
  partiesURL,
  vehiclesTimingsURL,
  warehouseTimingsURL,
  defaultHeaders,
  baseURL,
  invoicesURL,
  ordersURL,
  companiesURL,
  login,
  loginToken,
  invoicesMerge,
  invoicesBulkURL,
  gatePassURL,
  transporterDetailsURL,
  clean,
  pestControl,
  temperature,
  productsURL,
  inventoryURL,
  returnsURL,
  returnsBulkURL,
  returnsBulkPrefillURL,
  returnDetailsURL,
  alertsURL,
  ordersBulkURL,
  bulkOrdersPrefillURL,
  productMappingURL,
  templateURL,
  templateFailURL,
  headers,
  mapping
};

export default APIConstants;
